import React, { useEffect } from "react";
import Concept from "./Concept";

const ConceptProjectList = () => {
    const concepts = [
        { imageUrl: "/images/1.png" },
        { imageUrl: "/images/2.png" },
        { imageUrl: "/images/3.png" },
        { imageUrl: "/images/4.png" },
    ];

    useEffect(() => {
        const adjustImageHeight = () => {
            document.querySelectorAll('.ConceptImage').forEach(el => {
                el.style.height = 'auto';

                // Используем requestAnimationFrame для корректного вызова перерасчета
                requestAnimationFrame(() => {
                    el.getBoundingClientRect(); // Trigger reflow
                    el.style.height = ''; // Сброс высоты для пересчета
                });
            });
        };

        // Вызов функции после монтирования компонента
        adjustImageHeight();

        // Слушаем изменение размера окна для повторного вызова
        window.addEventListener('resize', adjustImageHeight);

        // Очищаем слушатель при размонтировании
        return () => {
            window.removeEventListener('resize', adjustImageHeight);
        };
    }, []);

    return (
        <div className="ConceptDiv">
            <div className="HeaderProject">
                <h2>Концепты</h2>
            </div>
            <div className="ConceptList">
                {concepts.map((concept, index) => (
                    <Concept key={index} imageUrl={concept.imageUrl} />
                ))}
            </div>
        </div>
    );
};

export default ConceptProjectList;
